import * as React from "react";

import { Checkbox, Modal, Select } from "antd";

import { v4 as uuid } from "uuid";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxTypeCounterLib from "@lib/box/box-type-counter";
import * as lensesLib from "@lib/lenses/lenses";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as renderFunctionLib from "@lib/box/render-function";

import LabelledElement from "@components/molecules/LabelledElement";

import { useState, useEffect } from "react";
import { ValueTypeKey } from "@lib/box/value-type";
import { AttributeMap, AttributeValue } from "@lib/box/attribute";
import { RenderFunction, RenderFunctionInfo } from "@lib/box/render-function";
import { isVariableStatement } from "typescript";
import { LensVisibilityList } from "@components/molecules/LensVisibilityList";


export interface AssociationToEnable {
	boxTypeKey: string;
	attributeTypeKey: string;
	attributeTypeVisibility: attributeTypeLib.AttributeTypeVisibility; 
}

export interface CreateAutomaticFilterChildBoxesProps {
	onAddButtonClick: (
		box: boxLib.Box, 
		editedBoxTypes: boxTypeLib.BoxTypeMap,
		associationsToEnable: AssociationToEnable[],
		lensGroupsToAdd: lensesLib.LensGroupMap,
		lensesToAdd: lensesLib.LensMap,
		lensVisibilityMap: lensesLib.LensVisibilityMap) => void,
		
	onCloseOrCancelButtonClick: () => void;
	isVisible: boolean;
	boxKey: string;
	boxChildTypeKey: string;
	box: boxLib.Box | undefined;
	flattenedBoxMap: boxLib.BoxMap;
	boxTypeMap: boxTypeLib.BoxTypeMap;
	boxTypeCounters: boxTypeCounterLib.BoxTypeCounters;
	attributeTypes: attributeTypeLib.AttributeTypeMap;
}


const CreateAutomaticFilterChildBoxesDialog: React.FC<CreateAutomaticFilterChildBoxesProps> = (props) => {

	// const [newBoxTypeName, setNewBoxTypeName] = useState<string>("");

	const [boxTypeKey, setBoxTypeKey] = useState<string | undefined>(undefined);

	const [attributeTypeKey, setAttributeTypeKey] = useState<string | undefined>(undefined);

	const [associationType, setAssociationType] = useState<"name" | "attribute">("name");

	const [badgeLabelType, setBadgeLabelType] = useState<"name" | "value">("value");

	const [createAutomaticBadges, setCreateAutomaticBadges] = useState(false);

	useEffect(() => {
		// setNewBoxTypeName("");
		setBoxTypeKey(undefined);
		setAttributeTypeKey(undefined);
		setCreateAutomaticBadges(false);
		setAssociationType("name");
		setBadgeLabelType("value");
	}, [props.isVisible])

	// Get the title
	const title = 'Create Automatic Filter Child Boxes';

	// Get the OK button text
	const okButtonText = 'Add';

	const boxTypesArray = props.boxTypeMap === undefined ? [] : Object.keys(props.boxTypeMap)
		.map((boxTypeKey) => {
			return {
				key: boxTypeKey,
				name: props.boxTypeMap !== undefined &&
					props.boxTypeMap[boxTypeKey] !== undefined ?
					props.boxTypeMap[boxTypeKey].name : ""
			}
		})
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});

	const attributeTypesArray = props.boxTypeMap === undefined || boxTypeKey === undefined ? [] :
		Object.keys(props.boxTypeMap[boxTypeKey].attributeTypes).map((attributeTypeKey) => {
			return {
				key: attributeTypeKey,
				name: props.boxTypeMap !== undefined &&
					boxTypeKey !== undefined &&
					props.boxTypeMap[boxTypeKey] !== undefined ?
					props.boxTypeMap[boxTypeKey].attributeTypes[attributeTypeKey].name : ""
			}
		});


	const newBoxValues: string[] = [];

	if (boxTypeKey !== undefined && attributeTypeKey !== undefined) {
		Object.keys(props.flattenedBoxMap).filter((boxkey) => {
			return props.flattenedBoxMap[boxkey].boxType === boxTypeKey;
		}).forEach((boxTypeKey) => {
			if (props.flattenedBoxMap[boxTypeKey] !== undefined) {
				const boxTypeObject = props.flattenedBoxMap[boxTypeKey];

				if (boxTypeObject.attributes !== undefined && boxTypeObject.attributes[attributeTypeKey] !== undefined) {

					const newValue = boxTypeObject.attributes[attributeTypeKey].toString();
					if (newBoxValues.indexOf(newValue) === -1) {
						newBoxValues.push(newValue);
					}
				}
			}
		});
	}

	newBoxValues.sort((a, b) => {
		if (a < b) {
			return -1;
		}

		if (a > b) {
			return 1;
		}

		return 0;
	});


	const createBadgeRenderFunctionInputs = (
		badgeLabelType: "name" | "value",
		sourceAttributeTypeName: string,
		parentBoxId: string,
		textColorAttributeId: string,
		backgroundColorAttributeId: string,
		backgroundImageAttributeId: string,
		borderRadiusAttributeId: string,
		horizontalPositionInPercentAttributeId: string,
		verticalPositionInPercentAttributeId: string,
		widthAttributeId: string,
		heightAttributeId: string,
		fontFamilyAttributeId: string,
		textSizeInPixelsAttributeId: string,
		verticalAlignmentAttributeId: string,
		horizontalAlignmentAttributeId: string
	): RenderFunctionInfo => {

		const badgeText = badgeLabelType === "value" ? "variables.filterBox.Name" : "variables.sourceAttributeTypeName";

		return {
			"name": "Filter Badge",
			"order": 1,
			"type": renderFunctionLib.RenderFunctionTypeKey.SET_BOX_PROPERTIES_FROM_EXPRESSIONS,
			"mode": renderFunctionLib.RenderFunctionMode.NORMAL,
			"inputs": {
				"boxPropertyExpressions": {
					"badge28": `{"text": ${badgeText}, "horizontalPositionInPercent": variables.horizontalPositionInPercent,  "verticalPositionInPercent": variables.verticalPositionInPercent, "verticalAlignment": variables.verticalAlignment,  "horizontalAlignment": variables.horizontalAlignment, "borderRadius": variables.borderRadius,  "backgroundImage": variables.backgroundImage, "backgroundColor": variables.backgroundColor,  "textColor": variables.textColor,  "width": variables.width, "height": variables.height, "fontFamily": variables.fontFamily, "textSizeInPixels": variables.textSizeInPixels  }`
				},
				"variables": [
					{
						"name": "sourceAttributeTypeName",
						"sourceType": "expression",
						"expression": `"${sourceAttributeTypeName}"`
					},
					{
						"name": "filterTable",
						"sourceType": "globalBoxChildrenById",
						"globalBoxUuid": parentBoxId
					},
					{
						"name": "filterBox",
						"sourceType": "expression",
						"expression": "variables.filterTable.first(f=> f.Name == attr)"
					},
					{
						"name": "textColor",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.textColorAttributeId]) { variables.filterBox[constants.textColorAttributeId] } else { \"#000000\" }"
					},
					{
						"name": "backgroundColor",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.backgroundColorAttributeId]) { variables.filterBox[constants.backgroundColorAttributeId] } else { \"#FFFFFF\" }"
					},
					{
						"name": "backgroundImage",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.backgroundImageAttributeId]) { variables.filterBox[constants.backgroundImageAttributeId] } else { \"\" }"
					},
					{
						"name": "borderRadius",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.borderRadiusAttributeId]) { toNumber(variables.filterBox[constants.borderRadiusAttributeId]) } else { 3 }"
					},
					{
						"name": "horizontalPositionInPercent",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.horizontalPositionInPercentAttributeId]) { toNumber(variables.filterBox[constants.horizontalPositionInPercentAttributeId]) } else { 0 }"
					},
					{
						"name": "verticalPositionInPercent",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.verticalPositionInPercentAttributeId]) { toNumber(variables.filterBox[constants.verticalPositionInPercentAttributeId]) } else { 0 }"
					},
					{
						"name": "width",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.widthAttributeId]) { toNumber(variables.filterBox[constants.widthAttributeId]) } else { 100 }"
					},
					{
						"name": "height",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.heightAttributeId]) { toNumber(variables.filterBox[constants.heightAttributeId]) } else { 16 }"
					},
					{
						"name": "fontFamily",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.fontFamilyAttributeId]) { variables.filterBox[constants.fontFamilyAttributeId] } else { \"Open Sans\" }"
					},
					{
						"name": "textSizeInPixels",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.textSizeInPixelsAttributeId]) { variables.filterBox[constants.textSizeInPixelsAttributeId] } else { \"13\" }"
					},
					{
						"name": "verticalAlignment",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.verticalAlignmentAttributeId]) { variables.filterBox[constants.verticalAlignmentAttributeId] } else { \"none\" }"
					},
					{
						"name": "horizontalAlignment",
						"sourceType": "expression",
						"expression": "if (variables.filterBox[constants.horizontalAlignmentAttributeId]) { variables.filterBox[constants.horizontalAlignmentAttributeId] } else { \"none\" }"
					}
				],
				"constants": {
					"textColorAttributeId": textColorAttributeId,
					"backgroundColorAttributeId": backgroundColorAttributeId,
					"backgroundImageAttributeId": backgroundImageAttributeId,
					"borderRadiusAttributeId": borderRadiusAttributeId,
					"horizontalPositionInPercentAttributeId": horizontalPositionInPercentAttributeId,
					"verticalPositionInPercentAttributeId": verticalPositionInPercentAttributeId,
					"widthAttributeId": widthAttributeId,
					"heightAttributeId": heightAttributeId,
					"fontFamilyAttributeId": fontFamilyAttributeId,
					"textSizeInPixelsAttributeId": textSizeInPixelsAttributeId,
					"verticalAlignmentAttributeId": verticalAlignmentAttributeId,
					"horizontalAlignmentAttributeId": horizontalAlignmentAttributeId
				}
			}
		};
	};


	const okButtonEnabled = newBoxValues.length > 0 && boxTypeKey !== undefined && attributeTypeKey !== undefined;

	const handleOKButtonClick = () => {

		// If we want to create the badge stuff and we don't have the base badge type then we're going to have to make it
		// Should just be able to add this in and it will be fine.
		// This might not work with mixins so we might have to just create the fields themselves
		// Start with that

		// As we iterate through all the children we will want to set the appropriate values.

		if (props.box &&
			props.boxTypeMap && props.attributeTypes && attributeTypeKey && boxTypeKey) {
			const updatedBox = JSON.parse(JSON.stringify(props.box)) as boxLib.Box;

			// Make a copy of the box types
			const editedBoxTypeMap = JSON.parse(JSON.stringify(props.boxTypeMap)) as boxTypeLib.BoxTypeMap;

			// Get the source boxType
			const sourceBoxType = editedBoxTypeMap[boxTypeKey];

			// Create a new box type
			const destinationBoxType = boxTypeLib.createDefaultBoxType();

			// Create the default values for the box type.
			boxTypeLib.setDefaultAttributeTypesForBoxType(destinationBoxType);

			const selectedAttributeType = attributeTypesArray.find((value, index) => { return value.key === attributeTypeKey; });

			if (selectedAttributeType) {

				const associationsToEnable: AssociationToEnable[] = [];

				destinationBoxType.name = `Filter.${selectedAttributeType.name}`;
				const destinationBoxTypeKey = uuid();

				// If we're a Name association type:
				// Source Association: Convert the selectedAttributeType to a "Names Lookup" 
				// the Permitted Association Box To the Destination Association
				// Destination Association: Name Reverse Lookup

				// If we're a doing an Attribute association:
				// source association: AttributeValueReverseLookup
				// destination association: AttributeValuesLookup

				const isAttributeAssociation = associationType === "attribute";

				// Create the Source Association Type
				// This is the original item
				const sourceAssocation = isAttributeAssociation ? attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Associations) : sourceBoxType.attributeTypes[selectedAttributeType.key];
				sourceAssocation.valueType = ValueTypeKey.Associations;
				sourceAssocation.associationsType = isAttributeAssociation ? attributeTypeLib.AssociationType.AttributeValueReverseLookup : attributeTypeLib.AssociationType.NamesLookup;
				sourceAssocation.associationsAttributeName = isAttributeAssociation ? selectedAttributeType.name : undefined;
				sourceAssocation.name = isAttributeAssociation ? `JOIN TO ${destinationBoxType.name}` : sourceAssocation.name;
				sourceAssocation.description = isAttributeAssociation ? `Joins to any boxes on ${destinationBoxType.name} that contain an attribute with the same value as ${selectedAttributeType.name}` : sourceAssocation.description;
				sourceAssocation.showInSummary = true;
				sourceAssocation.order = -1;

				sourceAssocation.permittedAssociationBoxTypes = destinationBoxTypeKey;

				// If it's an attribute association then we don't want to take across the render functions
				if (isAttributeAssociation) {
					sourceAssocation.renderFunctions = {};

				}

				// Create Attribute IDs for the badge Ids just in case we need them later
				const textColorAttributeId = uuid();
				const backgroundColorAttributeId = uuid();
				const backgroundImageAttributeId = uuid();
				const borderRadiusAttributeId = uuid();
				const horizontalPositionInPercentAttributeId = uuid();
				const verticalPositionInPercentAttributeId = uuid();
				const widthAttributeId = uuid();
				const heightAttributeId = uuid();
				const fontFamilyAttributeId = uuid();
				const textSizeInPixelsAttributeId = uuid();
				const verticalAlignmentAttributeId = uuid();
				const horizontalAlignmentAttributeId = uuid();

				// Make an ID for the new render function
				const newRenderFunctionId = uuid();

				const sourceRenderFunctionVisibilityMap: renderFunctionLib.RenderFunctionVisibilityMap = {};

				// Convert any existing render functions to normal ones and make sure they are hidden
				Object.keys(sourceAssocation.renderFunctions).forEach(k => {
					sourceAssocation.renderFunctions[k].mode = renderFunctionLib.RenderFunctionMode.NORMAL;
					sourceRenderFunctionVisibilityMap[k] = {isVisible: false};
				});

				// Create a new association render function so that it will highlight on handover
				const newAssociationRenderFunctionId = uuid();
				sourceAssocation.renderFunctions[newAssociationRenderFunctionId] = renderFunctionLib.createDefaultAssociationHighlightRenderFunction();

				// Make sure we do to enable the new association render function
				sourceRenderFunctionVisibilityMap[newAssociationRenderFunctionId] = {isVisible: true};

				// If it's a name assocation and we want to include box types
				if (!isAttributeAssociation && createAutomaticBadges) {
					// Set up the attributes on the box type
					const textColorAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					textColorAttribute.name = "Filter: Text Color";
					destinationBoxType.attributeTypes[textColorAttributeId] = textColorAttribute;

					const backgroundColorAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					backgroundColorAttribute.name = "Filter: Background Color";
					destinationBoxType.attributeTypes[backgroundColorAttributeId] = backgroundColorAttribute;

					const backgroundImageAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					backgroundImageAttribute.name = "Filter: Background Image";
					destinationBoxType.attributeTypes[backgroundImageAttributeId] = backgroundImageAttribute;

					const borderRadiusAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					borderRadiusAttribute.name = "Filter: Border Radius";
					destinationBoxType.attributeTypes[borderRadiusAttributeId] = borderRadiusAttribute;

					const horizontalPositionInPercentAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					horizontalPositionInPercentAttribute.name = "Filter: Horizontal Position In Percent";
					destinationBoxType.attributeTypes[horizontalPositionInPercentAttributeId] = horizontalPositionInPercentAttribute;

					const verticalPositionInPercentAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					verticalPositionInPercentAttribute.name = "Filter: Vertical Position In Percent";
					destinationBoxType.attributeTypes[verticalPositionInPercentAttributeId] = verticalPositionInPercentAttribute;

					const widthAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					widthAttribute.name = "Filter: Width";
					destinationBoxType.attributeTypes[widthAttributeId] = widthAttribute;

					const heightAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					heightAttribute.name = "Filter: Height";
					destinationBoxType.attributeTypes[heightAttributeId] = heightAttribute;

					const fontFamilyAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					fontFamilyAttribute.name = "Filter: Font Family";
					destinationBoxType.attributeTypes[fontFamilyAttributeId] = fontFamilyAttribute;

					const textSizeInPixelsAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					textSizeInPixelsAttribute.name = "Filter: Text Size In Pixels";
					destinationBoxType.attributeTypes[textSizeInPixelsAttributeId] = textSizeInPixelsAttribute;

					const verticalAlignmentAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					verticalAlignmentAttribute.name = "Filter: Vertical Alignment";
					destinationBoxType.attributeTypes[verticalAlignmentAttributeId] = verticalAlignmentAttribute;

					const horizontalAlignmentAttribute = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Text);
					horizontalAlignmentAttribute.name = "Filter: Horizontal Alignment";
					destinationBoxType.attributeTypes[horizontalAlignmentAttributeId] = horizontalAlignmentAttribute;

					// Create the Render Function
					sourceAssocation.renderFunctions[newRenderFunctionId] = createBadgeRenderFunctionInputs(
						badgeLabelType,
						sourceAssocation.name,
						props.boxKey,
						textColorAttributeId,
						backgroundColorAttributeId,
						backgroundImageAttributeId,
						borderRadiusAttributeId,
						horizontalPositionInPercentAttributeId,
						verticalPositionInPercentAttributeId,
						widthAttributeId,
						heightAttributeId,
						fontFamilyAttributeId,
						textSizeInPixelsAttributeId,
						verticalAlignmentAttributeId,
						horizontalAlignmentAttributeId
					);

					// We want the new badge render function to be disabled
					sourceRenderFunctionVisibilityMap[newRenderFunctionId] = {isVisible: false};
				}

				const sourceAssociationId = isAttributeAssociation ? uuid() : selectedAttributeType.key;

				// Add the ID of the source association to the array of associations that need to be enabled in the left hand menu
				associationsToEnable.push({ boxTypeKey: boxTypeKey, attributeTypeKey: sourceAssociationId, attributeTypeVisibility: {isVisible: true, renderFunctionVisibilityMap: sourceRenderFunctionVisibilityMap} });

				// Set the source association
				sourceBoxType.attributeTypes[sourceAssociationId] = sourceAssocation;

				// Add Source Association to the source box typeif (!isAttributeAssociation && createAutomaticBadges) {
				// This is the filter
				const destinationAssociation = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Associations);


				destinationAssociation.associationsType = isAttributeAssociation ? attributeTypeLib.AssociationType.AttributeValuesLookup : attributeTypeLib.AssociationType.NameReverseLookup;
				destinationAssociation.associationsAttributeName = selectedAttributeType.name;
				destinationAssociation.name = `JOIN To ${sourceBoxType.name}`;
				destinationAssociation.description = `Joins to ${sourceBoxType.name}.${selectedAttributeType.name}`;
				destinationAssociation.showInSummary = true;
				destinationAssociation.order = -1;
				destinationAssociation.permittedAssociationBoxTypes = boxTypeKey;

				destinationAssociation.renderFunctions = {};

				const destinationAssociationId = uuid();

				// Add the ID of the destination association to the array of associations that need to be enabled in the left hand menu
				associationsToEnable.push({ boxTypeKey: destinationBoxTypeKey, attributeTypeKey: destinationAssociationId, attributeTypeVisibility: {isVisible: true, renderFunctionVisibilityMap: {}} });

				// Enable the default association, too.
				associationsToEnable.push({ boxTypeKey: destinationBoxTypeKey, attributeTypeKey: "DefaultAssociation", attributeTypeVisibility: {isVisible: true, renderFunctionVisibilityMap: {}} });

				// Add the destination association to the destination box type
				destinationBoxType.attributeTypes[destinationAssociationId] = destinationAssociation;

				// Include the destination box type in the edited box type map
				editedBoxTypeMap[destinationBoxTypeKey] = destinationBoxType;

				// If there's no children, set up the child box map.
				if (!updatedBox.children) {
					updatedBox.children = {};
				}

				const updatedBoxChildren = updatedBox.children;

				// // New boxes are last in the box order
				// let highestOrder = boxLib.getHighestBoxOrder(updatedBoxChildren);

				// Given it's a new box type, the Ids are going to start from 1
				let highestId = 1;

				const lensGroupsToAdd: lensesLib.LensGroupMap = {};
				const lensesToAdd: lensesLib.LensMap = {};

				const lensVisibilityMap: lensesLib.LensVisibilityMap = {};

				// Iterate through every one of the new box values that we're going to create
				for (let i = 0; i < newBoxValues.length; i += 1) {
					const newChildBox = boxLib.createDefaultBox(destinationBoxTypeKey,
						editedBoxTypeMap,
						highestId);

					newChildBox.name = newBoxValues[i];

					boxLib.setDefaultBoxAttributes(editedBoxTypeMap, newChildBox, highestId);

					if (newChildBox.attributes) {
						newChildBox.attributes[destinationAssociationId] = newBoxValues[i];

						if (!isAttributeAssociation && createAutomaticBadges) {
							newChildBox.attributes[textColorAttributeId] = "#000000";
							newChildBox.attributes[backgroundColorAttributeId] = "#DCDCDC";
							newChildBox.attributes[borderRadiusAttributeId] = "3";
							newChildBox.attributes[horizontalPositionInPercentAttributeId] = "0";
							newChildBox.attributes[verticalPositionInPercentAttributeId] = "0";
							newChildBox.attributes[widthAttributeId] = "80";
							newChildBox.attributes[heightAttributeId] = "16";
							newChildBox.attributes[fontFamilyAttributeId] = "Arial";
							newChildBox.attributes[textSizeInPixelsAttributeId] = "13";
							newChildBox.attributes[verticalAlignmentAttributeId] = "none";
							newChildBox.attributes[horizontalAlignmentAttributeId] = "none";
						}
					}

					const newChildBoxKey = uuid();

					updatedBoxChildren[newChildBoxKey] = newChildBox;

					highestId += 1;

					// Create a lens that matches up with this value if required.
					if (associationType === "name" && createAutomaticBadges) {
						const lensId = uuid();
						lensesToAdd[lensId] = {
							name: newBoxValues[i],
							longName: sourceBoxType.name + " - " + sourceAssocation.name + " - " + newBoxValues[i],
							order: i,
							parameters: {},
							queries: [
								{
									name: newBoxValues[i],
									type: lensesLib.QueryType.DEFAULT,
									applyBoxTypeVisibility: true,
									applyBoxVisibility: true,
									attributeExpression: {
										operator: lensesLib.AttributeExpressionOperator.EXCLUDES,
										attributeType: attributeTypeKey,
										attributeValue: newBoxValues[i],
										attributeParameter: "",
										children: [],
										childrenExpressionOperator: lensesLib.BooleanOperator.AND,
										childrenOperator:lensesLib.BooleanOperator.AND,
									},
									attributeTypeExpressionOperator: lensesLib.BooleanOperator.AND,
									attributeTypeExpressions: {},
									boxExpressionOperator: lensesLib.BooleanOperator.AND,
									boxExpressions: {},
									boxIsInLayout: true,
									boxIsVisible: false,
									boxTypeAreBoxesInLayout: true,
									boxTypeAreBoxesVisible: true,
									boxTypeAttributeTypeVisibilityMap: {},
									boxTypeExpressionOperator: lensesLib.BooleanOperator.AND,
									boxTypeExpressions: {
										[boxTypeKey]: lensesLib.EqualityOperator.EQUAL
									},
									boxTypeIsVisible: true,
									boxTypeMixinBoxTypeVisibilityMap: {},
									operator: lensesLib.BooleanOperator.AND
								}
							],
							description: ""
						}
					}
				}

				// Create the Show Lens
				// Create a lens that matches up with this value if required.
				if (associationType === "name" && createAutomaticBadges) {
					const lensId = uuid();
					lensesToAdd[lensId] = {
						name: "Show Badge",
						longName: sourceBoxType.name + " - " + sourceAssocation.name + " - Show Badge",
						order: highestId + 1,
						parameters: {},
						queries: [
							{
								name: "Show Badge",
								type: lensesLib.QueryType.DEFAULT,
								applyBoxTypeVisibility: false,
								applyBoxVisibility: false,
								attributeExpression: undefined,
								attributeTypeExpressionOperator: lensesLib.BooleanOperator.AND,
								attributeTypeExpressions: {},
								boxExpressionOperator: lensesLib.BooleanOperator.AND,
								boxExpressions: {},
								boxIsInLayout: true,
								boxIsVisible: true,
								boxTypeAreBoxesInLayout: true,
								boxTypeAreBoxesVisible: true,
								boxTypeAttributeTypeVisibilityMap: {
									[attributeTypeKey]: {
										isVisible: true,
										renderFunctionVisibilityMap: {
											[newRenderFunctionId]: { isVisible: true }
										}
									}
								},
								boxTypeExpressionOperator: lensesLib.BooleanOperator.AND,
								boxTypeExpressions: {
									[boxTypeKey]: lensesLib.EqualityOperator.EQUAL
								},
								boxTypeIsVisible: true,
								boxTypeMixinBoxTypeVisibilityMap: {},
								operator: lensesLib.BooleanOperator.AND
							}
						],
						description: ""
					}

					// Make the Lens visibile
					lensVisibilityMap[lensId] = true;
				}

				// If we're creating the automatic lenses, then we need to make the Lens Group
				if (associationType === "name" && createAutomaticBadges) {
					const lensGroupId = uuid();
					lensGroupsToAdd[lensGroupId] = {
						name: selectedAttributeType.name,
						order: 99,
						lensKeys: Object.getOwnPropertyNames(lensesToAdd)
					}
				}



				props.onAddButtonClick(updatedBox, editedBoxTypeMap, associationsToEnable, lensGroupsToAdd, lensesToAdd, lensVisibilityMap);
			}
		}
	};

	return (
		<Modal
			title={title}
			open={props.isVisible}
			okText={okButtonText}
			okButtonProps={{ disabled: !okButtonEnabled }}
			onOk={handleOKButtonClick}
			cancelButtonProps={{ disabled: false }}
			onCancel={props.onCloseOrCancelButtonClick}
			zIndex={9999}
			width={"50%"}
		>
			<div>
				<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns: "0.2fr 0.8fr",
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}
				>

					<LabelledElement labelText="Source Box Type">
						<Select
							key={`input-operator`}
							size="large"
							placeholder="Please select"
							value={boxTypeKey ? boxTypeKey : ""}
							onChange={(newKey: string) => {
								setAttributeTypeKey(undefined);
								setBoxTypeKey(newKey !== "" ? newKey : undefined);
							}}
							style={{ width: "100%" }}
							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return popupContainer as HTMLElement;
							}}
						>
							<Select.Option key="" value="">- Please select -</Select.Option>

							{
								boxTypesArray.map((attributeType, index) => {
									return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
								})
							}
						</Select>
					</LabelledElement>

					<LabelledElement labelText="Source Attribute Type">
						<Select
							key={`input-operator`}
							size="large"
							placeholder="Please select"
							value={attributeTypeKey ? attributeTypeKey : ""}
							onChange={(newKey: string) => {
								setAttributeTypeKey(newKey !== "" ? newKey : undefined);
							}}
							style={{ width: "100%" }}
							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return popupContainer as HTMLElement;
							}}
						>
							<Select.Option key="" value="">- Please select -</Select.Option>

							{
								attributeTypesArray.map((attributeType, index) => {
									return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
								})
							}
						</Select>
					</LabelledElement>

					<LabelledElement labelText="Association Type">
						<Select
							key={`input-operator`}
							size="large"
							placeholder="Please select"
							value={associationType}
							onChange={(newKey) => {
								setAssociationType(newKey);
							}}
							style={{ width: "100%" }}
							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return popupContainer as HTMLElement;
							}}
						>
							<Select.Option key="name" value="name">Name</Select.Option>
							<Select.Option key="attribute" value="attribute">Attribute</Select.Option>
						</Select>
					</LabelledElement>
					{associationType === "name" &&
						<LabelledElement labelText="Create Automatic Badges">
							<Checkbox
								checked={createAutomaticBadges}
								onChange={(e) => setCreateAutomaticBadges(e.target.checked)} />

						</LabelledElement>
					}

					{associationType === "name" && createAutomaticBadges &&
						<LabelledElement labelText="Badge Label Type">
							<Select
								key={`input-operator`}
								size="large"
								placeholder="Please select"
								value={badgeLabelType}
								onChange={(newKey) => {
									setBadgeLabelType(newKey);
								}}
								style={{ width: "100%" }}
								getPopupContainer={(node) => {
									let popupContainer: HTMLElement | null =
										window.document.documentElement;
									if (node && node.parentElement) {
										popupContainer = node.parentElement;
									}
									return popupContainer as HTMLElement;
								}}
							>
								<Select.Option key="value" value="value">Attribute Value</Select.Option>
								<Select.Option key="name" value="name">Attribute Name</Select.Option>

							</Select>
						</LabelledElement>

					}

					{
						boxTypeKey && attributeTypeKey &&
						<LabelledElement labelText="Values to Create">
							<ul>
								{newBoxValues.length < 1 ? <li>No Values Found</li> : newBoxValues.map((val, index) => {
									return <li key={index}>{val}</li>;
								})}
							</ul>
						</LabelledElement>
					}
				</div>
			</div>
		</Modal>
	);
}


export default CreateAutomaticFilterChildBoxesDialog;