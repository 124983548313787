import * as React from "react";

import { Select, Modal, Checkbox } from "antd";

import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import { AttributeList } from "../../../molecules/AttributeList";
import { PropertyList } from "../../../molecules/PropertyList";

import * as propertyLib from "@lib/box/box-properties";
import * as attributeLib from "@lib/box/attribute";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";

import { BoxStyleMap } from "@lib/box/box-style";
import { createBoxEmptyPropertyMap } from '@lib/box/box-properties'

type UniqueBoxTypeMap = { [key: string]: boolean };
type BoxTypeKeyAttributeMap = { [key: string]: attributeLib.AttributeMap };

interface MultiEditBoxesDialogProps {
	onOKButtonClick: (boxes: boxLib.BoxMap | undefined) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	boxKeys: string[];
	flattenedBoxMap: boxLib.BoxMap | undefined;
	boxParentMap: boxLib.BoxParentMap | undefined;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	boxStyles: BoxStyleMap | undefined;
}

interface MultiEditBoxesDialogState {
	boxTypeKeyAttributeMap: BoxTypeKeyAttributeMap;
	defaultProperties: propertyLib.PropertyMap;
	boxTypeKeys: string[];
	overrideBoxType: boolean;
	currentBoxTypeKey: string;
}

export class MultiEditBoxesDialog extends React.Component<
	MultiEditBoxesDialogProps,
	MultiEditBoxesDialogState
> {
	constructor(props: MultiEditBoxesDialogProps) {
		super(props);

		// Get the updated state
		const updatedState = this.getUpdatedStateForMultiEditBoxes(
			props.flattenedBoxMap,
			props.boxParentMap,
			props.boxKeys
		);

		// Set up our state
		this.state = updatedState;
	}

	public componentWillReceiveProps(nextProps: MultiEditBoxesDialogProps) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Get the updated state
			const updatedState = this.getUpdatedStateForMultiEditBoxes(
				nextProps.flattenedBoxMap,
				nextProps.boxParentMap,
				nextProps.boxKeys
			);

			// Update our state
			this.setState(updatedState);
		}
	}

	public render() {
		// Render the box types
		const renderedBoxTypes = this.state.overrideBoxType
			? this.renderBoxTypes(this.props.boxTypes)
			: null;

		// Render the attribute types
		const renderedAttributeTypes = !this.state.overrideBoxType
			? this.renderAttributeTypes()
			: null;

		// Render the box properties
		const renderedBoxProperties = this.renderBoxDefaultProperties(
			undefined
		);

		const dialogTitle = (
			<div
				style={{
					width: "100%",
					boxSizing: "border-box",
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gap: "4px 4px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<div>Edit Boxes</div>
				<div style={{ textAlign: "right", paddingRight: "24px" }}>
					<Checkbox
						checked={this.state.overrideBoxType}
						onChange={this.handleOverrideBoxTypeChange}
					>
						Override Types
					</Checkbox>
				</div>
			</div>
		);

		const boxTypesTitle = (
			<h3
				style={{
					paddingTop: "8px",
					paddingBottom: "8px",
					paddingLeft: "12px",
					paddingRight: "8px",
					color: "white",
					backgroundColor: "#282c34",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<span
					style={{
						paddingTop: "2px",
					}}
				>
					Type
				</span>
			</h3>
		);

		const attributesTitle = (
			<h3
				style={{
					paddingTop: "8px",
					paddingBottom: "8px",
					paddingLeft: "12px",
					paddingRight: "8px",
					color: "white",
					backgroundColor: "#282c34",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<span
					style={{
						paddingTop: "2px",
					}}
				>
					Attributes
				</span>
			</h3>
		);

		const propertiesTitle = (
			<h3
				style={{
					paddingTop: "8px",
					paddingBottom: "8px",
					paddingLeft: "12px",
					paddingRight: "8px",
					color: "white",
					backgroundColor: "#282c34",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<span
					style={{
						paddingTop: "2px",
					}}
				>
					Properties
				</span>
			</h3>
		);

		return (
			<Modal
				title={dialogTitle}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				width={"90%"}
				zIndex={9999}
				centered
				bodyStyle={{
					maxHeight: "80vh",
					overflowY: "auto",
					boxSizing: "border-box",
				}}
			>
				{this.state.overrideBoxType && (
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							flexWrap: "wrap",
						}}
					>
						<div style={{ width: "100%", paddingBottom: "4px" }}>
							{boxTypesTitle}
						</div>
						<div
							style={{
								width: "100%",
								paddingBottom: "12px",
								paddingLeft: "12px",
							}}
						>
							{renderedBoxTypes}
						</div>
					</div>
				)}
				{!this.state.overrideBoxType && (
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							flexWrap: "wrap",
						}}
					>
						<div style={{ width: "100%", paddingBottom: "4px" }}>
							{attributesTitle}
						</div>
						<div
							style={{
								width: "100%",
								paddingBottom: "12px",
								paddingLeft: "12px",
							}}
						>
							{renderedAttributeTypes}
						</div>
					</div>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "flex-start",
						flexWrap: "wrap",
					}}
				>
					<div style={{ width: "100%", paddingBottom: "4px" }}>
						{propertiesTitle}
					</div>
					<div style={{ width: "100%", paddingLeft: "12px" }}>
						{renderedBoxProperties}
					</div>
				</div>
			</Modal>
		);
	}

	private getUpdatedStateForMultiEditBoxes = (
		flattenedBoxMap: boxLib.BoxMap | undefined,
		boxParentMap: boxLib.BoxParentMap | undefined,
		boxKeys: string[]
	) => {
		// If we don't have flattened box map or box parent map, the state is empty
		if (!flattenedBoxMap || !boxParentMap) {
			// Build the state
			const state: MultiEditBoxesDialogState = {
				boxTypeKeyAttributeMap: {},
				defaultProperties: createBoxEmptyPropertyMap(),
				boxTypeKeys: [],
				overrideBoxType: false,
				currentBoxTypeKey: "",
			};

			return state;
		}

		// Get the types of the boxes we're editing
		const boxTypeMap: UniqueBoxTypeMap = this.props.boxKeys.reduce(
			(reducedBoxTypeMap: UniqueBoxTypeMap, boxKey: string) => {
				// Get the box
				const box = flattenedBoxMap![boxKey];
				if (box) {
					// Get the box type key
					const boxTypeKey = box.boxType;

					// Do we not already have the box type?
					if (
						!Object.prototype.hasOwnProperty.call(
							reducedBoxTypeMap,
							boxTypeKey
						)
					) {
						reducedBoxTypeMap[boxTypeKey] = true;
					}
				}

				return reducedBoxTypeMap;
			},
			{}
		);

		// Get the box type keys
		const boxTypeKeys = Object.keys(boxTypeMap);

		// Set up the box type attributes
		const boxTypeKeyAttributeMap: BoxTypeKeyAttributeMap = boxTypeKeys.reduce(
			(
				reducedBoxTypeAttributeMap: BoxTypeKeyAttributeMap,
				boxTypeKey: string
			) => {
				reducedBoxTypeAttributeMap[boxTypeKey] = {};
				return reducedBoxTypeAttributeMap;
			},
			{}
		);

		// Build the state
		const state: MultiEditBoxesDialogState = {
			boxTypeKeyAttributeMap,
			defaultProperties: createBoxEmptyPropertyMap(),
			boxTypeKeys,
			overrideBoxType: false,
			currentBoxTypeKey: "",
		};

		return state;
	};

	private handleOKButtonClick = () => {
		// console.log('handleOKButtonClick');
		// console.log(this.state.box);

		// Get a copy of the the boxes we're editing
		const updatedMultiEditBoxes: boxLib.BoxMap = this.props.boxKeys.reduce(
			(reducedMultiEditBoxes: boxLib.BoxMap, boxKey: string) => {
				reducedMultiEditBoxes[boxKey] = JSON.parse(
					JSON.stringify(this.props.flattenedBoxMap![boxKey])
				);
				return reducedMultiEditBoxes;
			},
			{}
		);

		// Update the boxes
		Object.keys(this.state.boxTypeKeyAttributeMap).forEach(
			(boxTypeKey: string) => {
				Object.keys(updatedMultiEditBoxes).forEach((boxKey: string) => {					
					const boxTypesEqual = (updatedMultiEditBoxes[boxKey].boxType === undefined && boxTypeKey === 'undefined') 
						|| updatedMultiEditBoxes[boxKey].boxType === boxTypeKey;
					
					// Does the box have the type we're looking for, and does it have
					// attributes and default properties?
					if (
						boxTypesEqual &&
						updatedMultiEditBoxes[boxKey].attributes &&
						updatedMultiEditBoxes[boxKey].defaultProperties
					) {
						// Are we overriding the box type?
						if (this.state.overrideBoxType) {
							updatedMultiEditBoxes[
								boxKey
							].boxType = this.state.currentBoxTypeKey;

							// Clear the attributes
							updatedMultiEditBoxes[boxKey].attributes = {};
						} else {
							// Get the attributes
							const updatedAttributes = this.state
								.boxTypeKeyAttributeMap[boxTypeKey];

							// Update the attributes
							Object.keys(updatedAttributes).forEach(
								(attributeKey) => {
									// Get the updated attribute value
									const updatedAttributeValue =
										updatedAttributes[attributeKey];
									if (updatedAttributeValue !== undefined) {
										// Set the attribute value if it's not undefined
										updatedMultiEditBoxes[
											boxKey
										].attributes![
											attributeKey
										] = updatedAttributeValue;
									}
								}
							);
						}

						// Update the default properties
						Object.keys(this.state.defaultProperties).forEach(
							(propertyKey) => {
								// Get the updated property value
								const propertyValue = this.state
									.defaultProperties[propertyKey];
								if (propertyValue !== undefined) {
									// Set the property if it's not empty
									updatedMultiEditBoxes[
										boxKey
									].defaultProperties![
										propertyKey
									] = propertyValue;
								}
							}
						);
					}
				});
			}
		);

		// console.log('updatedMultiEditBoxes');
		// console.log(updatedMultiEditBoxes);

		// Notify the callback that the OK button has been clicked, passing the
		// editing boxes
		this.props.onOKButtonClick(updatedMultiEditBoxes);
	};

	private handleOverrideBoxTypeChange = (event: CheckboxChangeEvent) => {
		// Get whether we're overriding the box type
		const overrideBoxType = event.target.checked;

		const boxTypeKeys = this.props.boxTypes
			? Object.keys(this.props.boxTypes)
			: [];

		const firstBoxTypeKey = boxTypeKeys.length > 0 ? boxTypeKeys[0] : "";

		const currentBoxTypeKey = overrideBoxType
			? firstBoxTypeKey
			: this.state.currentBoxTypeKey;

		// Update the state with the new box properties
		this.setState({
			overrideBoxType,
			currentBoxTypeKey,
		});
	};

	private handleBoxTypeChange = (boxTypeKey: string) => {
		// Update the state with the new box type key
		this.setState({
			currentBoxTypeKey: boxTypeKey,
		});
	};

	private handlePropertyChange = (
		propertyKey: string,
		propertyValue: propertyLib.PropertyValue | undefined
	) => {
		// A copy of the default properties
		const updatedDefaultProperties = JSON.parse(
			JSON.stringify(this.state.defaultProperties)
		);

		// Update the default properties
		if (propertyValue !== undefined) {
			console.log(`setting property ${propertyKey} to ${propertyValue}`);
			updatedDefaultProperties[propertyKey] = propertyValue;
		} else {
			console.log(`deleting property ${propertyKey}`);
			delete updatedDefaultProperties[propertyKey];
		}

		// Update our state
		this.setState({
			defaultProperties: updatedDefaultProperties,
		});
	};

	private handleAttributeChange = (
		boxTypeKey: string,
		attributeKey: string,
		attributeValue: attributeLib.AttributeValue | undefined
	) => {
		// Get a copy of the box type attributes
		const updatedBoxTypeKeyAttributeMap = JSON.parse(
			JSON.stringify(this.state.boxTypeKeyAttributeMap)
		);

		// Update the attribute
		const updatedAttributeMap = updatedBoxTypeKeyAttributeMap[boxTypeKey];
		if (attributeValue !== undefined) {
			console.log(
				`setting attribute ${attributeKey} to ${attributeValue}`
			);
			updatedAttributeMap[attributeKey] = attributeValue;
		} else {
			console.log(`deleting attribute ${attributeKey}`);
			delete updatedAttributeMap[attributeKey];
		}

		console.log(
			"updatedBoxTypeKeyAttributeMap",
			updatedBoxTypeKeyAttributeMap
		);

		// Update our state
		this.setState({
			boxTypeKeyAttributeMap: updatedBoxTypeKeyAttributeMap,
		});
	};

	private renderBoxTypes = (boxTypes: boxTypeLib.BoxTypeMap | undefined) => {
		//console.log(boxTypes)

		// Render each of the box types as an option
		const renderedBoxTypeOptions = boxTypes
			? Object.keys(boxTypes).map((boxTypeKey) => {
				// Get the box type
				const boxType = boxTypes[boxTypeKey];

				// Get the name of the box type
				const boxTypeName = boxType.name;

				// Render the box type option
				return (
					<Select.Option key={boxTypeKey} value={boxTypeKey}>
						{boxTypeName}
					</Select.Option>
				);
			})
			: null;

		// TODO: Figure out why select menu isn't being displayed
		// Do we need to set getPopupContainer={(triggerNode: Element) =>
		// popupContainer} ?

		return (
			<Select
				onChange={this.handleBoxTypeChange}
				value={this.state.currentBoxTypeKey}
				style={{ width: "100%" }}
				getPopupContainer={(node) => {
					let popupContainer: HTMLElement | null =
						window.document.documentElement;
					if (node && node.parentElement) {
						popupContainer = node.parentElement;
					}
					return popupContainer as HTMLElement;
				}}
			>
				{renderedBoxTypeOptions}
			</Select>
		);
	};

	private renderBoxDefaultProperties = (
		attributeTypes: attributeTypeLib.AttributeTypeMap | undefined
	) => {
		return (
			<PropertyList
				onPropertyChange={this.handlePropertyChange}
				defaultProperties={this.state.defaultProperties}
				attributeTypes={attributeTypes}
				boxStyles={this.props.boxStyles}
				displayChangedCheckboxes
			/>
		);
	};

	private renderBoxTypeAttributeTypes = (boxTypeKey: string) => {
		// If we don't have any box types there's nothing to render
		if (!this.props.boxTypes) {
			return null;
		}

		// Get the attributes
		const attributes = this.state.boxTypeKeyAttributeMap[boxTypeKey];

		return (
			<AttributeList
				onAttributeChange={(
					attributeKey: string,
					attributeValue: attributeLib.AttributeValue | undefined
				) =>
					this.handleAttributeChange(
						boxTypeKey,
						attributeKey,
						attributeValue
					)
				}
				attributes={attributes}
				boxTypeKey={boxTypeKey}
				boxTypes={this.props.boxTypes}
				boxMap={this.props.flattenedBoxMap}
				displayNonAssociations
				displayAssociations
				displayChangedCheckboxes
				flattenedBoxMap={this.props.flattenedBoxMap ? this.props.flattenedBoxMap : {}}
			/>
		);
	};

	private renderAttributeTypes = () => {
		// If we don't have box types there's nothing to render
		if (!this.props.boxTypes) {
			return null;
		}

		// Render the attribute types
		const renderedAttributeTypes = Object.keys(
			this.state.boxTypeKeyAttributeMap
		).map((boxTypeKey) => {
			// Render the attribute types
			const renderedBoxTypeAttributeTypes = this.renderBoxTypeAttributeTypes(
				boxTypeKey
			);

			// Get the box type
			const currentBoxTypeKey = this.props.boxTypes![boxTypeKey];

			// Get the name of the box type
			const boxTypeName = currentBoxTypeKey ? currentBoxTypeKey.name : "";

			// Get the names of the boxes that have that type
			const boxKeys = boxLib
				.getBoxKeysForBoxTypeKey(this.props.flattenedBoxMap, boxTypeKey)
				.filter((boxKey) => this.props.boxKeys.indexOf(boxKey) >= 0);

			const boxNames = boxLib.getBoxNamesForBoxKeys(
				this.props.flattenedBoxMap,
				boxKeys
			);

			// Build them in to a comma separated string
			const boxNamesCommaSeparated = boxNames.join(", ");

			// Build the header text
			const headerText =
				boxNames.length > 0
					? `${boxTypeName} - ${boxNamesCommaSeparated}`
					: `${boxTypeName}`;

			return (
				<div key={boxTypeKey}>
					<div
						style={{
							paddingTop: "4px",
							paddingBottom: "8px",
							marginBottom: "8px",
							backgroundColor: "#f8f8f8",
						}}
					>
						<b>{headerText}</b>
					</div>
					{renderedBoxTypeAttributeTypes}
				</div>
			);
		});

		return renderedAttributeTypes;
	};
}
