import * as React from "react";

import { Modal, Checkbox, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { UploadFile } from "antd/lib/upload/interface";

import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";

import {
	getWorksheetNameToBoxTypeKeyMap,
	convertExcelToBoxAttributeMap,
	convertBoxTypeAttributesToExcel,
	applyBoxAttributeMapToBoxChildren,
	getWorksheetNameForBoxTypeKey,
	getBoxChildAttributesForType,
	downloadExcel
} from './import-export-excel'

export interface ImportExportChildBoxesExcelDialogProps {
	onImportButtonClick: (box: boxLib.Box, boxTypes: boxTypeLib.BoxTypeMap) => void;
	onCloseOrCancelButtonClick: () => void;
	isImporting: boolean;
	isVisible: boolean;
	boxKey: string;
	boxChildTypeKey: string;
	box: boxLib.Box | undefined;
	boxMap: boxLib.BoxMap | undefined;
	flattenedBoxMap: boxLib.BoxMap | undefined;
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined;
}

interface ImportExportChildBoxesExcelDialogState {
	includeUnnamedBoxes: boolean;
	importFileList: UploadFile<any>[];
	importWorkBook: XLSX.WorkBook | undefined;
	includeAssociations: boolean;
}

export class ImportExportChildBoxesExcelDialog extends React.Component<
	ImportExportChildBoxesExcelDialogProps,
	ImportExportChildBoxesExcelDialogState
> {
	constructor(props: ImportExportChildBoxesExcelDialogProps) {
		super(props);

		// Set up our state
		this.state = {
			includeUnnamedBoxes: false,
			importFileList: [],
			importWorkBook: undefined,
			includeAssociations: false
		};
	}

	public componentWillReceiveProps(
		nextProps: ImportExportChildBoxesExcelDialogProps
	) {
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			// Set up our state
			this.setState({
				includeUnnamedBoxes: false,
				importFileList: [],
				importWorkBook: undefined,
				includeAssociations: false
			});
		}
	}

	public render() {
		// Get the title
		const title = this.props.isImporting
			? "Import Child Boxes From Excel"
			: "Export Child Boxes To Excel";

		// Get the OK button text
		const okButtonText = this.props.isImporting ? "Import" : "Export";

		// If we're importing and no files are selected, disable the import button
		const okButtonDisabled = this.props.isImporting
			? this.state.importFileList.length <= 0
			: false;

		return (
			<Modal
				title={title}
				open={this.props.isVisible}
				okText={okButtonText}
				okButtonProps={{ disabled: okButtonDisabled }}
				onOk={this.handleOKButtonClick}
				cancelButtonProps={{ disabled: false }}
				onCancel={this.props.onCloseOrCancelButtonClick}
				zIndex={9999}
				width={"50%"}
			>
				{!this.props.isImporting && (
					<React.Fragment>
						<div
							style={{
								width: "100%",
								display: "grid",
								gridTemplateColumns: "1fr 1fr 1fr",
								gap: "0px 12px",
								paddingBottom: "12px",
								margin: 0,
								overflowY: "visible",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
								}}
							>
								<Checkbox
									checked={this.state.includeUnnamedBoxes}
									name="includeUnnamedBoxes"
									onChange={
										this.handleIncludeUnnamedBoxesChange
									}
								>
									Include Unnamed Boxes
								</Checkbox>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
								}}
							>
								<Checkbox
									checked={this.state.includeAssociations}
									name="includeAssociations"
									onChange={this.handleIncludeAssociationsChange}
								>
									Include Associations
								</Checkbox>
							</div>
						</div>
					</React.Fragment>
				)}
				{this.props.isImporting && (
					<div
						style={{
							marginTop: "1em",
						}}
					>
						{this.renderUpload()}
					</div>
				)}
			</Modal>
		);
	}

	private renderUpload = () => {
		return (
			<Upload.Dragger
				name="file"
				accept=".xls,.xlsx"
				multiple={false}
				action=""
				beforeUpload={this.handleBeforeUpload}
				fileList={this.state.importFileList}
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Click or drag an Excel file to this area to upload
				</p>
				<p className="ant-upload-hint">
					Only Excel files previously exported by Enterprise Lens are
					supported
				</p>
			</Upload.Dragger>
		);
	};

	private handleIncludeUnnamedBoxesChange = (e: CheckboxChangeEvent) => {
		if (e != null && e.target.name !== undefined) {
			this.setState({ includeUnnamedBoxes: e.target.checked });
		}
	};


	private handleIncludeAssociationsChange = (e: CheckboxChangeEvent) => {
		if (e != null && e.target.name !== undefined) {
			this.setState({ includeAssociations: e.target.checked });
		}
	};

	private handleOKButtonClick = () => {
		// Are we importing
		if (this.props.isImporting) {
			this.readImportedExcelFile(this.state.importFileList[0])
				.then((workbook: XLSX.WorkBook) => {
					if (this.props.box &&
						this.props.boxMap &&
						this.props.flattenedBoxMap &&
						this.props.boxTypeMap) {
						const worksheetNameToBoxTypeKeyMap = getWorksheetNameToBoxTypeKeyMap(
							this.props.boxTypeMap
						);

						// Make sure a worksheet exists for the child box type
						const worksheetName = getWorksheetNameForBoxTypeKey(this.props.boxChildTypeKey,
							worksheetNameToBoxTypeKeyMap);
						if (!worksheetName) {
							return;
						}

						// Convert the workbook to a box attribute map
						const { boxAttributeMap, boxTypeMap } = convertExcelToBoxAttributeMap(
							workbook,
							this.props.flattenedBoxMap,
							this.props.boxTypeMap,
							worksheetNameToBoxTypeKeyMap,
							true
						);

						const updatedBox = applyBoxAttributeMapToBoxChildren(this.props.box,
							this.props.boxChildTypeKey,
							boxTypeMap,
							boxAttributeMap)

						// Notify the parent that the Import button has been clicked, passing the updated box
						// updated box
						this.props.onImportButtonClick(updatedBox, boxTypeMap);
					}
				})
				.catch((err: Error) => {
					console.log(err);
				});
		} else {
			if (
				this.props.box &&
				this.props.box.children &&
				this.props.boxMap &&
				this.props.flattenedBoxMap &&
				this.props.boxTypeMap
			) {
				const boxChildTypeKey = this.props.boxChildTypeKey;

				// Get the attributes of the box children
				const boxChildAttributes = getBoxChildAttributesForType(
					this.props.box,
					boxChildTypeKey,
					this.props.boxTypeMap,
					this.state.includeUnnamedBoxes,
					this.state.includeAssociations
				);

				let workbookPromise:
					| Promise<XlsxPopulate.Workbook>
					| undefined = undefined;
	
				// Convert them to an Excel spreadsheet
				workbookPromise = convertBoxTypeAttributesToExcel(
					this.props.box.children,
					this.props.flattenedBoxMap,
					boxChildAttributes,
					this.props.boxTypeMap,
					this.state.includeAssociations,
					this.props.flattenedBoxMap
				);
		
				if (workbookPromise) {
					// Build the spreadsheet name
					const spreadsheetName = `${this.props.box.name}.xlsx`;
		
					// Download the spreadsheet
					downloadExcel(workbookPromise, spreadsheetName);
				}
			}

			// Notify the parent that the Close button has been clicked
			this.props.onCloseOrCancelButtonClick();
		}
	};

	private readImportedExcelFile = (file: any) => {
		return new Promise(
			(
				resolve: (workbook: XLSX.WorkBook) => void,
				reject: (reason: any) => void
			) => {
				const reader = new FileReader();

				reader.onload = (event: any) => {
					try {
						// Get the loaded data
						const loadedData = event.target.result;
						if (!loadedData) {
							reject(new Error("Unable to load file"));
							return;
						}

						// Convert it to a workbook
						const workbook = XLSX.read(loadedData, {
							type: "binary",
						});

						// We've loaded the workbook
						resolve(workbook);
					} catch (e) {
						reject(e);
					}
				};

				reader.readAsBinaryString(file);
			}
		);
	};

	private handleBeforeUpload = (file: any) => {
		this.setState({ importFileList: [file] });
		return false;
	};
}
